exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-en-about-js": () => import("./../../../src/pages/en/about.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-contacts-js": () => import("./../../../src/pages/en/contacts.js" /* webpackChunkName: "component---src-pages-en-contacts-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-job-js": () => import("./../../../src/pages/en/job.js" /* webpackChunkName: "component---src-pages-en-job-js" */),
  "component---src-pages-en-offer-js": () => import("./../../../src/pages/en/offer.js" /* webpackChunkName: "component---src-pages-en-offer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-js": () => import("./../../../src/pages/job.js" /* webpackChunkName: "component---src-pages-job-js" */),
  "component---src-pages-offer-js": () => import("./../../../src/pages/offer.js" /* webpackChunkName: "component---src-pages-offer-js" */),
  "component---src-pages-page-slug-js": () => import("./../../../src/pages/{Page.slug}.js" /* webpackChunkName: "component---src-pages-page-slug-js" */)
}

